import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoPs from './../../assets/images/logo-ps.png'
import LogoSubtitle from './../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose, faEnvelope, faGear, faHome, faSuitcase, faUser ,} from '@fortawesome/free-solid-svg-icons'
import {faGithub, faInstagram, faLinkedin, faTelegram, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'


const Sidebar = () => {

    const [showNav, setShowNav] = useState(false);

    return (<>
    <div className='nav-bar'>
        <Link className='logo' to="/">
            <img src={LogoPs} alt='' />
            <img className='sub-logo' src={LogoSubtitle} alt=''/>
        </Link>
        <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink exact='true' activeclassname='active' to='/' onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faHome} color='#4d4d4e'/>
            </NavLink>
            <NavLink exact='true' activeclassname='active' className="about-link"to='/about' onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faUser} color='#4d4d4e'/>
            </NavLink>
            <NavLink exact='true' activeclassname='active' className="skill-link" to='/skills' onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faGear} color='#4d4d4e'/>
            </NavLink>
            <NavLink exact='true' activeclassname='active' className="portfolio-link" to='/portfolio' onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faSuitcase} color='#4d4d4e'/>
            </NavLink>
            <NavLink exact='true' activeclassname='active' className="contact-link" to='/contact' onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e'/>
            </NavLink>
            <FontAwesomeIcon 
                onClick={() => setShowNav(false)}
                icon={faClose}
                color="#000"
                size="3x"
                className='close-icon' />
        </nav>
        <ul>
            <li>
                <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/prashant-naik-b12280125/'>
                    <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a target='_blank' rel='noreferrer' href='https://github.com/PS-PrashantFSD'>
                    <FontAwesomeIcon icon={faGithub} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a target='_blank' rel='noreferrer' href='https://twitter.com/psprashantIN'>
                    <FontAwesomeIcon icon={faTwitter} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a target='_blank' rel='noreferrer' href='https://www.instagram.com/ps_prashant68/'>
                    <FontAwesomeIcon icon={faInstagram} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a target='_blank' rel='noreferrer' href='https://wa.me/9481615582'>
                    <FontAwesomeIcon icon={faWhatsapp} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a target='_blank' rel='noreferrer' href='https://msng.link/o?@ps_prasha=tg'>
                    <FontAwesomeIcon icon={faTelegram} color='#4d4d4e'/>
                </a>
            </li>
        </ul>
        <FontAwesomeIcon
        onClick={() => setShowNav(true)}
        icon={faBars}
        color='#ffd700'
        size='3x'
        className='hamburger-icon'
        />
    </div>
    </>)

}

export default Sidebar