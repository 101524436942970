import './App.scss';
import {Routes, Route} from  'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Skills from './components/Skills';
import More from './components/More';
import Particle from './components/Particle/Particle';

function App() {
  return (
    <>
    <Particle/>
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Home/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/skills' element={<Skills/>}/>
        <Route path='/more' element={<More/>}/>
      </Route>
    </Routes>
    </>
  );
}

export default App;
