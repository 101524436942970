import React, { useEffect, useState } from 'react'
import './index.scss'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import portfolioData from './../../data/portfolio.json'
import { Link } from 'react-router-dom'



const Portfolio = () => {
    const [letterClass, setLetterClass] = useState('text-animate')


    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])


    const renderPortfolio = (portfolio) => {
        return (
            <div className='images-container'>
                {
                    portfolio.map((port, idx) => {
                        return (
                            <div className='image-box' key={idx}>
                                <img src={port.cover} className='portfolio-image' alt='portfolio' />
                                <div className='content-p'>
                                    <p className='title'>{port.title}</p>
                                    <h4 className='description'>{port.description}</h4>
                                    <h3 className='tools'>{port.tools}</h3>
                                    <button className='btn' onClick={()=> window.open(port.url)}>View</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    return (
        <>
        <div className='container portfolio-page'>
            <h1 className='page-title'>
                <AnimatedLetters
                letterClass={letterClass} 
                strArray={"Projects".split("")}
                idx={15}
                />
            </h1>
            <p className='p-tag'>Explore my diverse portfolio of creative projects and web applications, each with captivating cover images and concise descriptions. Click 'View' to discover more
            <Link to='/more'  className='flat-m-button'>More..</Link>   
            </p>
            <div>
                
            </div>
            <div>{renderPortfolio(portfolioData.portfolio)}</div>
        </div>
        <Loader type='pacman'/>
        </>
    )
}

export default Portfolio