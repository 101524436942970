import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LogoTitle from '../../assets/images/logo-ps.png'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Profilepic from './../../assets/images/profile-pic.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGithub, faInstagram, faLinkedin, faTelegram, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const Home = ( ) => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['p','r','a','s','h','a','n','t']
    const jobArray = ['F','u','l','l',' ', 's','t','a','c','k', ' ', 'd','e','v','e','l','o','p','e','r']

    useEffect(() => {
        setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 4000)
      }, [])


    return (
        <>
            <div className="container home-page home-page-mobile">
                <div className="text-zone">
                    <h1> 
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>i,</span>
                    <br />
                    <span className={`${letterClass} _13`}>I</span>
                    <span className={`${letterClass} _14`}>'m</span>
                    <img src={LogoTitle}  alt="developer"/>
                    <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                    <br/>
                    <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22}/>
                    </h1>
                    <h2>Frontend Developer / Backend Developer / React Developer</h2>
                    <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                </div>
                <div className='profile-pic-container'>
                    <div className='profile-pic'>
                        <div className='content'>
                            <img src={Profilepic} alt=''/>
                            <h2>PS Prashant<br/><span>Full Stack Developer</span></h2>
                            <a href='mailto:psprashantanilgod@gmail.com'>Hire Me</a>  
                        </div>
                    </div>
                    <div className="mobile-socialmedia">
                      <a className='abs' target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/prashant-naik-b12280125/">
                        <FontAwesomeIcon icon={faLinkedin}size="2x" color="#fff" />
                      </a>
                      <a className='abs' target="_blank" rel="noreferrer" href="https://github.com/PS-PrashantFSD">
                        <FontAwesomeIcon icon={faGithub} size="2x"color="#fff" />
                      </a>
                      <a className='abs' target="_blank" rel="noreferrer" href="https://twitter.com/psprashantIN">
                        <FontAwesomeIcon icon={faTwitter} size="2x"color="#fff" />
                      </a>
                      <a className='abs' target="_blank" rel="noreferrer" href="https://www.instagram.com/ps_prashant68/">
                        <FontAwesomeIcon icon={faInstagram} size="2x"color="#fff" />
                      </a>
                      <a className='abs' target="_blank" rel="noreferrer" href="https://wa.me/9481615582">
                        <FontAwesomeIcon icon={faWhatsapp} size="2x"color="#fff" />
                      </a>
                      <a className='abs' target="_blank" rel="noreferrer" href="https://msng.link/o?@ps_prasha=tg">
                        <FontAwesomeIcon icon={faTelegram}  size="2x" color="#fff" />
                      </a>
                    </div>
                </div>
            </div>
            <Loader type='pacman' />
        </>
    )
}

export default Home