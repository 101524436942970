import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Loader from 'react-loaders'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'


const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault();
      
        const form = e.target;

        const userId = '0Rwuh8wuaH-FQYkIO';
      
        emailjs
          .sendForm("service_1w9dzx9", 'template_15eotlo', form, userId)
          .then(
            () => {
              alert('Message successfully sent!');
              window.location.reload(false);
            },
            () => {
              alert('Failed to send the message, please try again');
            }
          );
      };

    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        Passionate about exploring freelance opportunities, especially those involving ambitious and large projects.
                        However, I am also open to other requests or questions. Don't hesitate to get in touch using the form below.
                        Let's collaborate and make great things happen together!
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type='text' name='name' placeholder='Name' required />
                                </li>
                                <li className='half'>
                                    <input type='email' name='email' placeholder='Email' required />
                                </li>
                                <li>
                                    <input type='text' name='subject' placeholder='Subject' required />
                                </li>
                                <li >
                                    <textarea placeholder='Message' name='message' required></textarea>
                                </li>
                                <li className='lil'>
                                    <input type='submit' className='flat-button' value='SEND' />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div className='info-map'>
                    Prashant Shripati Naik
                    <br/>
                    Banglore,
                    <br/>
                    Karnatak, India <br/>
                    <span>psprashantanilgod@gmail.com</span>
                </div>
                <div className='map-wrap'>
                    <MapContainer center={[12.9716, 77.5946]} zoom={13}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        <Marker position={[12.9716, 77.5946]}>
                            <Popup>Prashant lives here.</Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <Loader type='pacman' />
        </>
    )
}

export default Contact;
