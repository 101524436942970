import React, { useState, useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';

import DesinftLogo from './../../assets/images/desinftclub-logo.png';
import pic1 from './../../assets/images/logos/1.jpg';
import pic2 from './../../assets/images/logos/2.jpg';
import pic3 from './../../assets/images/logos/3.jpg';
import pic5 from './../../assets/images/logos/5.jpg';
import pic6 from './../../assets/images/logos/6.jpg';
import pic7 from './../../assets/images/logos/7.jpg';
import pic10 from './../../assets/images/logos/10.jpg';

import Pic1 from './../../assets/images/art/1.png';
import Pic2 from './../../assets/images/art/2.png';
import Pic3 from './../../assets/images/art/3.png';
import Pic4 from './../../assets/images/art/4.png';
// import Pic5 from './../../assets/images/art/5.png';
import Pic7 from './../../assets/images/art/7.png';
import Pic8 from './../../assets/images/art/8.png';
import Pic9 from './../../assets/images/art/9.png';
import Pic10 from './../../assets/images/art/10.png';
// import Pic11 from './../../assets/images/art/11.png';

const More = () => {
  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  return (
    <>
      <div className='container more-page more-container'>
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={'Portfolio'.split('')}
            idx={15}
          />
        </h1>
        <div className='nft-area'>
          <h2 className='head-h2'>Logo's</h2>
          <p>Which I Designed.</p>
          <div className='logo-box'>
            <img className='img-tag' src={DesinftLogo} alt='dsnlogo' />
            <img className='img-tag' src={pic1} alt='' />
            <img className='img-tag' src={pic2} alt='' />
            <img className='img-tag' src={pic3} alt='' />
            <img className='img-tag' src={pic5} alt='' />
            <img className='img-tag' src={pic6} alt='' />
            <img className='img-tag' src={pic7} alt='' />
            <img className='img-tag' src={pic10} alt='' />
          </div>
          <h2 className='head-h2'>NFT's</h2>
          <p>
            I have designed 360+ Desi art using pixel Art tool with that
            programmatically generated the 6000+ NFT's using 50+ backgrounds.{' '}
            <a
              href='https://opensea.io/collection/desi-nft-club'
              target='_blank'
              rel='noopener noreferrer'
            >
              Check the Art Collections
            </a>
          </p>
        </div>
        <div className='nody'>
          <div className='box-zone'>
            <div>
            <img src={Pic1} alt='nft1'  />
            <img src={Pic2} alt='nft2' />
            <img src={Pic3} alt='nft3' />
            <img src={Pic4} alt='nft4' />
            {/* <img src={Pic5} alt='nft5' /> */}
            </div>
            <div>
            <img src={Pic7} alt='nft7'  />
            <img src={Pic8} alt='nft8'/>
            <img src={Pic9} alt='nft9'/>
            <img src={Pic10} alt='nft10'  />
            {/* <img src={Pic11} alt='nft11' /> */}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default More;
