import React, {useEffect, useState} from 'react'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBootstrap, faCss3, faGitAlt, faHtml5, faJs, faReact ,  faGithub, faAws } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'



const Skills = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])


    return (
        <>
            <div className='container skill-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={"Skills".split("")}
                            idx={15}
                        />
                    </h1>
                    <p>As a full stack developer, I possess a versatile and comprehensive skill set that enables me to proficiently 
                        handle both front-end and back-end aspects of web development. On the front-end, I am adept at creating 
                        captivating user interfaces and user experiences using HTML, CSS, and JavaScript. I excel in implementing 
                        responsive designs, ensuring seamless interactions across various devices. 
                    </p>
                    <p>
                        Additionally, my expertise in 
                        front-end frameworks such as React and Other Libraries allows me to build dynamic and interactive applications. 
                        On the back-end, I am well-versed in server-side programming languages like Node.js, ExpressJs, and Redux, 
                        enabling me to develop robust and efficient server logic.
                    </p>
                    <p>I am done projects with databases like 
                        MySQL, MongoDB, and Mongoose, ensuring efficient data storage and retrieval. My proficiency in utilizing 
                        RESTful APIs and integrating third-party services further enhances the functionality of the applications 
                        I develop. As a full stack developer, I constantly strive to stay updated with the latest technologies 
                        and best practices, ensuring that I can deliver scalable, secure, and high-performing solutions for any 
                        project I undertake.
                    </p>
                    <p>
                    For more details check my <a href='https://www.linkedin.com/in/prashant-naik-b12280125/' target="_blank" rel="noopener noreferrer">LinkedIn</a> profile.
                    </p>
                    <h4>Skills</h4>
                    <div className='skills'>
                        Javascript | HTML-5 | CSS | NodeJs | ExpressJs | Flex Box | Git | MangoDB |Mongoose | GitHub | AWS Cloud Computing | MS
                        React | Redux |My SQL | API |
                        Front-End | Back-End | Problem Solving Approach | 
                    </div>
                </div>

                <div className='skill-set'>
                    <div className='skill-set-line'>
                        <div className='set'>
                            <div className='face'>
                                <FontAwesomeIcon className="iconi" icon={faJs} color='#DD0031'/>
                            </div>
                            <div className='face'>
                                <FontAwesomeIcon className="iconi" icon={faHtml5} color='#F06529'/>
                            </div>
                            <div className='face'>
                                <FontAwesomeIcon className="iconi" icon={faCss3} color='#28A4D9'/>
                            </div>
                            <div className='face'>
                                <FontAwesomeIcon  className="iconi" icon={faReact} color='#5ED4F4'/>
                            </div>
                        </div>
                        <div className='set'>
                        <div className='face'>
                            <FontAwesomeIcon className="iconi"icon={faGitAlt} color='EC4D'/>
                        </div>
                        <div className='face'>
                            <FontAwesomeIcon className="iconi" icon={faBootstrap} color='#EFD81D'/>
                        </div>
                        <div className='face'>
                            <FontAwesomeIcon  className="iconi" icon={faAws} color='#232F3E'/>
                        </div>
                        <div className='face'>
                            <FontAwesomeIcon className="iconi" icon={faGithub} color='#EFD81D'/>
                        </div> 
                        </div>
                        
                    </div>
                </div>
            </div>
            <Loader type='pacman' />
        </>
    )
}
export default Skills